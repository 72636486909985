import React from 'react'
// import Educationfour from '../../../static/images/education4.jpg'
// import P1 from '../../static/images/p1.png'
import Sectiontwoimg from '../../../static/images/aboutimg.png'

export default function header() {
  return (
    <div>
        <section class=" lg:pb-4 px-6 bg-white" style={{fontFamily:"Poppins"}}>
  {/* <nav class="py-4 bg-white">
    <div class="container mx-auto px-4">
      <div class="relative flex items-center justify-between">
        <div class="w-auto">
          <a class="inline-block" href="#">
            <img src={Log} alt=""/>
          </a>
        </div>
        <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hidden lg:block">
          <ul class="flex items-center">
            <li class="font-heading mr-12"><a class="hover:text-black" href="#">About</a></li>
            <li class="font-heading mr-12"><a class="hover:text-black" href="#">Company</a></li>
            <li class="font-heading mr-12"><a class="hover:text-black" href="#">Services</a></li>
            <li class="font-heading mr-12"><a class="hover:text-black" href="#">Testimonials</a></li>
          </ul>
        </div>
        <div class="w-auto hidden lg:block"><a class="inline-block py-4 px-8 font-heading font-medium text-base text-white bg-green-500 hover:bg-green-600 border border-green-500 hover:border-green-600 rounded-sm transition duration-200" href="#">Contact</a></div>
        <div class="w-auto lg:hidden">
          <a class="navbar-burger inline-flex w-14 h-14 justify-center items-center bg-gray-500 hover:bg-gray-600 rounded-full" href="#">
            <svg width="20" height="10" viewbox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19 9H1M19 1H1" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </nav> */}
  <div class=" py-20 md:mb-4 lg:py-24 lg:w-full lg:h-auto bg-white">
    <div class="relative  px-4 mx-auto">
      <div class="w-full xl:w-1/2 px-4 mb-24 xl:mb-0">
        <div class="max-w-md md:max-w-lg mx-auto">
          <div class="text-sm mb-2 font-semibold text-indigo-900 uppercase">
            <span>Risk management</span>
            <span class="text-sky-600">  &amp; Actuarial Solution</span>
          </div>
          <h1 class="font-bold text-4xl md:text-5xl text-indigo-900 mb-6">We help you in carving your own path to success</h1>
          <p class="text-lg leading-8  text-indigo-900 mb-10">Empowering clients with right knowlegde and tools to help business problems and make meaningful business decisions</p>
          <div class="sm:flex items-center"><a class="inline-block w-full sm:w-auto py-4 px-6 mb-4 sm:mb-0 sm:mr-4 text-center font-heading font-medium text-base text-white bg-sky-600 hover:bg-indigo-900 border-sky-600 hover:border-green-600 border border-sky-600 hover:border-green-600 rounded-lg transition duration-200" href="/contact">Enquire Now</a></div>
        </div>
      </div>
      <img class="invisible lg:visible xl:absolute top-0 right-5 lg:mr-10 block lg:mx-auto -mb-52 xl:-mb-0 lg:h-96 " src={Sectiontwoimg} alt=""/>
    </div>
  </div>
  {/* <div class="relative container px-4 mx-auto">
    <a class="inline-block text-gray-900" href="#">
      <svg width="26" height="30" viewbox="0 0 26 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13 1V29M13 29L25 17M13 29L1 17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
      </svg>
    </a>
  </div> */}
  {/* <div class="hidden navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-sm z-50">
    <div class="navbar-backdrop fixed inset-0 backdrop-blur-xl backdrop-filter bg-gray-900 bg-opacity-80"></div>
    <nav class="relative pt-7 pb-8 bg-white h-full overflow-y-auto">
      <div class="flex flex-col px-6 h-full">
        <a class="inline-block ml-4 mb-7" href="#">
          <img src={''} alt=""/>
        </a>
        <ul class="w-full mb-auto pb-16">
          <li><a class="font-heading block text-base font-medium py-4 px-6 hover:bg-green-50 rounded-sm" href="#">Home</a></li>
          <li><a class="font-heading block text-base py-4 px-6 hover:bg-green-50 rounded-sm" href="#">About</a></li>
          <li><a class="font-heading block text-base py-4 px-6 hover:bg-green-50 rounded-sm" href="#">Company</a></li>
          <li><a class="font-heading block text-base py-4 px-6 hover:bg-green-50 rounded-sm" href="#">Services</a></li>
          <li><a class="font-heading block text-base py-4 px-6 hover:bg-green-50 rounded-sm" href="#">Testimonials</a></li>
          <li><a class="font-heading block text-base py-4 px-6 hover:bg-green-50 rounded-sm" href="#">Contact</a></li>
        </ul>
        <div class="w-full">
          <a class="block w-full py-4 px-4 mb-4 text-center font-heading font-medium text-base hover:text-green-500 border border-gray-900 hover:border-green-500 rounded-sm transition duration-200" href="#">Log in</a><a class="block w-full py-4 px-4 mb-8 text-center font-heading font-medium text-base text-white bg-green-500 hover:bg-green-600 border border-green-500 hover:border-green-600 rounded-sm transition duration-200" href="#">Sign up</a>
          <p class="pl-2 text-sm">2022 &copy; Shuffle</p>
        </div>
      </div>
    </nav>
  </div> */}
</section>
    </div>
  )
}