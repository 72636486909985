import React from 'react'
import Navbar from '../components/navbar'
import AboutUs from '../components/AboutUs/aboutone'

import Footer from '../components/footer'
import Companyhead from '../components/AboutUs/companyhead'
export default function about() {
  return (
    <div>
       <Navbar/> 
       <AboutUs/>
       <Companyhead/>
       {/* <Team/> */}
       {/* <Faq/> */}
       <Footer/>
    </div>
  )
}
